<template>
    <div>
        <div class="page-title flex flex-space-between">
            <span>管理员设置</span>
            
            <role-owner>
                <button class="btn btn-white" @click="addAdmin">添加管理员</button>
            </role-owner>
        </div>
        
        <pay-box title="已有子管理员" :sub-title="`(${seatTotal - seatLost} / ${seatTotal})`">
            <table v-if="masters" class="masters-table">
                <template v-for="(master,mk) in masters">
                    
                    <tr :key="mk">
                        <td>
                            <img class="avatar" :src="master.avatar" alt="">
                            <span class="ms-name">{{ master.mark_name || master.name }}</span>
                        </td>
                        <td>
                            <span class="sp">ID: {{ master.member_id }}</span>
                        </td>
                        <td>
                            <template v-if=" master.admin_expired_at ">
                                <span class="sp">有效期截止 {{ $formatTime(master.admin_expired_at) }}</span>
                            </template>
                            <template v-else>
                                <span class="sp">永久有效</span>
                            </template>
                        </td>
                        <td style="width: 100px">
                            <a v-if=" master.seat_expired_at " class="xf" @click="createOrder(master.seat_id)">续费</a>
                        </td>
                        <td>
                            <button class="btn" @click="delMaster(master)">解除权限</button>
                        </td>
                    </tr>
                
                </template>
            </table>
            <div v-else class="flex-center">
                - 暂无管理员 -
            </div>
        </pay-box>
        
        <at-modal v-model="showAddModal" title="添加管理员" width="408px" @ok="confirmAddAdmin">
            <div class="admin-member" v-if="member">
                <img :src="member.avatar" alt="">
                <div>
                    <div class="mn">{{ member.mark_name || member.name }}</div>
                    <div class="md">ID:{{ member.member_id }}</div>
                </div>
            </div>
            <div v-else class="add-admin-container">
                <div>请输入管理员ID或扫描下方二维码</div>
                <div class="input-container">
                    <at-input v-model="memberKeywords" class="full-with">
                        <div @click="searchMember" class="search-btn"></div>
                    </at-input>
                </div>
                <div class="qrcode">
                    <img v-if="qrcodeUrl" class="qrcode-img" :src="qrcodeUrl" alt="">
                </div>
            </div>
        </at-modal>
        
        <at-modal v-model="showPayModal" width="408px" :footer="null">
            <div slot="title" class="seat-tips" v-if=" paySeatId === 0 ">
                <div>当前可添加管理员人数已达上限{{ seatTotal }}人！</div>
                <div>如需继续添加请付费开通</div>
            </div>
            <div v-else slot="title"> 管理员席位续费</div>
            <div class="qrcode">
                <img v-if="qrcodeUrl" class="qrcode-img" :src="qrcodeUrl" alt="">
            </div>
            <div class="pay-info">{{ payPrice }}元/年.人</div>
        </at-modal>
    
    </div>
</template>

<script>
import api from "@/repo/api";
import PayBox from "@/views/AST/Pay/Step/PayBox.vue";
import AtModal from "@/components/AtModal.vue";
import AtInput from "@/components/AtInput.vue";
import auth from "@/auth";
import RoleOwner from "@/components/roles/RoleOwner.vue";

export default {
    name: "Admin",
    components: {RoleOwner, AtInput, AtModal, PayBox},
    data() {
        return {
            showPayModal: false,
            showAddModal: false,
            masters: undefined,
            member: undefined,
            qrcodeUrl: "",
            memberKeywords: "",
            seatTotal: 0,
            seatLost: 0,
            payPrice: "?",
            timerFlag: null,
            paySeatId: 0,
        }
    },
    mounted() {
        this.fetchMasters()
    },
    destroyed() {
        if (this.timerFlag) clearTimeout(this.timerFlag)
    },
    methods: {
        delMaster(item) {
            this.$confirm({
                title: "确定要删除这个管理员吗？",
                onOk: () => {
                    
                    this.$loading.show()
                    
                    api.post('/ast-app/remove-admin', {
                        member_id: item.member_id
                    }, res => {
                        
                        this.$message.data(res)
                        
                        if (res.code === 0) this.fetchMasters()
                        
                    })
                }
            })
        },
        fetchNums() {
            api.get('/ast-app/admin-seat-num', res => {
                this.seatTotal = res.data.total;
                this.seatLost = res.data.num;
            })
        },
        addAdmin() {
            this.$loading.show()
            
            api.get('/ast-app/admin-seat-num', res => {
                
                this.$loading.hide()
                
                this.seatTotal = res.data.total;
                this.seatLost = res.data.num;
                
                if (res.data.num > 0) {
                    this.member = undefined;
                    this.refreshQrcode()
                    this.showAddModal = true
                } else {
                    this.createOrder(0)
                }
                
            })
            
        },
        createOrder(paySeatId) {
            this.$loading.show()
            
            this.qrcodeUrl = ""
            this.paySeatId = paySeatId
            
            api.post('/ast-app/create-admin-order', {
                'seat_id': paySeatId
            }, res => {
                
                this.$loading.hide()
                
                if (res.code !== 0) return this.$message.data(res)
                
                this.qrcodeUrl = res.data.data_url
                this.payPrice = res.data.price
                
                this.showPayModal = true
                
                this.fetchOrderStatus(res.data.no)
                
            })
        },
        fetchOrderStatus(no) {
            setTimeout(() => {
                
                api.get('/order/status', {no}, res => {
                    
                    if (!res.data.status) return this.fetchOrderStatus(no)
                    
                    this.showPayModal = false
                    
                    if (this.paySeatId) {
                        this.$message.success('续费成功');
                        this.fetchMasters()
                    } else {
                        this.$message.success('购买成功');
                        this.addAdmin()
                    }
                })
                
            }, 3000)
        },
        refreshQrcode() {
            let baseUrl = 'https://api.ycsd.work/api/collocation/ast-app/admin-invite-qrcode?';
            
            baseUrl += '&token=' + auth.getToken();
            baseUrl += '&_mini_app_id=' + auth.appId();
            baseUrl += '&t=' + (new Date).getTime();
            
            this.qrcodeUrl = baseUrl
        },
        confirmAddAdmin() {
            if (!this.member) return;
            
            this.$loading.show()
            
            api.post('/ast-app/add-admin', {am_id: this.member.id}, res => {
                
                this.$message.data(res)
                this.$loading.hide()
                
                if (res.code === 0) {
                    this.showAddModal = false
                    this.fetchMasters()
                }
                
            })
        },
        searchMember() {
            
            api.get('/ast-member/members', {keywords: this.memberKeywords}, (data) => {
                
                this.memberListLoading = false
                let members = data.data.data;
                
                if (!members.length) {
                    return this.$message.error('该用户未进入过本小程序，请下方微信扫码添加');
                }
                
                if (members.length > 5) members = members.slice(0, 5)
                
                this.member = members[0]
            })
            
        },
        fetchMasters() {
            
            this.$loading.show()
            
            this.fetchNums()
            
            api.get('/ast-app/admins', {}, res => {
                
                this.$loading.hide()
                
                this.masters = res.data;
            })
        }
    }
}
</script>

<style scoped lang="less">
.qrcode {
    width: 240px;
    height: 240px;
    border-radius: 2px 2px 2px 2px;
    margin: 0 auto;
    background-color: #dddddd;
}

.pay-info {
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: center;
    margin-top: 12px;
}

.input-container {
    margin: 16px 0 24px 0;
}

.avatar {
    width: 56px;
    height: 56px;
    border-radius: 53px 53px 53px 53px;
    margin-right: 16px;
}

.sp {
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 16px;
}

.masters-table {
    width: 100%;
    
    td {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px dashed #eee;
    }
    
    tr:first-child td {
        padding-top: 0;
    }
}

.xf {
    font-weight: 400;
    font-size: 14px;
    color: #FBB03B;
    line-height: 16px;
}

.search-btn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #333333;
    background-image: url("/assets/icons/btn-search.png");
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center;
}

.qrcode-img {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
}

.admin-member {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    img {
        width: 48px;
        height: 48px;
        border-radius: 52px 52px 52px 52px;
        margin-right: 12px;
    }
    
    .mn {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 16px;
        margin-bottom: 8px;
    }
    
    .md {
        font-size: 12px;
        color: #999999;
        line-height: 14px;
    }
}

.seat-tips {
    font-weight: 500;
    font-size: 16px;
    color: #F5606D;
    line-height: 22px;
    text-align: center;
}
</style>